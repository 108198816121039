import '../GlobalStyles.css'; // Import the CSS file
import React, { useState, useEffect, useContext } from "react";
import "../GlobalStyles.css";
import SearchableTable from '../search/SearchableTable.js';
import { useUser } from "../../contexts/UserContext.js";
import { Tabs, Tab, Alert } from 'react-bootstrap';


const IrongateTracker = () => {

    const filters = {
        "tracker_type": ["irongate"]
    }

    const { user } = useUser();

    const irongateOrgDict = {
        'Voyager Space': 'Voyager Space',
        'Expeto': 'Expeto',
        'Morpheus Space': 'Morpheus Space',
        'Lonestar Data Holdings': 'Lonestar Data Holdings',
        'Second Front Systems': 'Second Front Systems',
        'Pison': 'Pison',
        'Spartan Radar': 'Spartan Radar',
        'Privateer': 'Privateer',
        'AON3D': 'AON3D',
        'Pryon': 'Pryon',
        'Databuoy': 'Databuoy',
        'goTenna': 'goTenna',
        'skyfi': 'skyfi',
        'Firestorm Labs': 'Firestorm Labs',
        'Oros Labs': 'Oros Labs',
        'Infomatics.ai': 'Infomatics.ai',
        'Limacharlie': 'Limacharlie',
        'Zeno Power': 'Zeno Power',
        'Hidden Level': 'Hidden Level',
        'Phoenix Semiconductor': 'Phoenix Semiconductor',
        'RunSafe Security': 'RunSafe Security',
        'Red 6': 'Red 6',
        'Sayari': 'Sayari',
        'TrueFort': 'TrueFort',
        'CesiumAstro': 'CesiumAstro',
        'Blueridge Networks': 'Blueridge Networks',
        'Apkudo': 'Apkudo',
        'Atomic-6': 'Atomic-6'
    }

    const customOrgOptions = [
        { label: 'Voyager Space', value: 'Voyager Space' },
        { label: 'Expeto', value: 'Expeto' },
        { label: 'Morpheus Space', value: 'Morpheus Space' },
        { label: 'Lonestar Data Holdings', value: 'Lonestar Data Holdings' },
        { label: 'Second Front Systems', value: 'Second Front Systems' },
        { label: 'Pison', value: 'Pison' },
        { label: 'Spartan Radar', value: 'Spartan Radar' },
        { label: 'Privateer', value: 'Privateer' },
        { label: 'AON3D', value: 'AON3D' },
        { label: 'Pryon', value: 'Pryon' },
        { label: 'Databuoy', value: 'Databuoy' },
        { label: 'goTenna', value: 'goTenna' },
        { label: 'skyfi', value: 'skyfi' },
        { label: 'Firestorm Labs', value: 'Firestorm Labs' },
        { label: 'Oros Labs', value: 'Oros Labs' },
        { label: 'Infomatics.ai', value: 'Infomatics.ai' },
        { label: 'Limacharlie', value: 'Limacharlie' },
        { label: 'Zeno Power', value: 'Zeno Power' },
        { label: 'Hidden Level', value: 'Hidden Level' },
        { label: 'Phoenix Semiconductor', value: 'Phoenix Semiconductor' },
        { label: 'RunSafe Security', value: 'RunSafe Security' },
        { label: 'Red 6', value: 'Red 6' },
        { label: 'Sayari', value: 'Sayari' },
        { label: 'TrueFort', value: 'TrueFort' },
        { label: 'CesiumAstro', value: 'CesiumAstro' },
        { label: 'Blueridge Networks', value: 'Blueridge Networks' },
        { label: 'Apkudo', value: 'Apkudo' },
        { label: 'Atomic-6', value: 'Atomic-6' }
    ];

    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>IronGate Portfolio</h1>
            <Tabs defaultActiveKey="mentions" id="uncontrolled-tab-example" className="border-3">
                <Tab eventKey="mentions" title="Media Mentions">
                    <SearchableTable
                        baseRequest={filters}
                        mediaMonitor={true}
                        orgsOfInterest={irongateOrgDict}
                        showOrgs={true}
                        orgOptions={customOrgOptions}
                        excludePast24Hours={true}
                        trackerType={["irongate"]}
                    />
                </Tab>
                <Tab eventKey="about" title="About">
                    <br />
                    <h4 style={{ textAlign: 'center' }}>
                        This tracker monitors 230,000+ outlets in real-time for mentions of IronGate portfolio companies.
                    </h4>
                </Tab>
            </Tabs>
        </div>
    );
};

export default IrongateTracker;
