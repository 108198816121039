import { useParams } from "react-router-dom";
import "../GlobalStyles.css";
import SearchableTable from "../search/SearchableTable.js";
import { useUser } from "../../contexts/UserContext.js";
import Alert from "react-bootstrap/Alert";


const representativeList = [
    { firstName: 'Jerry', lastName: 'Carl' },
    { firstName: 'Barry', lastName: 'Moore' },
    { firstName: 'Mike', lastName: 'Rogers' },
    { firstName: 'Robert', lastName: 'Aderholt' },
    { firstName: 'Dale', lastName: 'Strong' },
    { firstName: 'Gary', lastName: 'Palmer' },
    { firstName: 'Terri', lastName: 'Sewell' },
    { firstName: 'Mary', lastName: 'Peltola' },
    { firstName: 'Aumua', lastName: 'Radewagen' },
    { firstName: 'David', lastName: 'Schweikert' },
    { firstName: 'Elijah', lastName: 'Crane' },
    { firstName: 'Ruben', lastName: 'Gallego' },
    { firstName: 'Greg', lastName: 'Stanton' },
    { firstName: 'Andy', lastName: 'Biggs' },
    { firstName: 'Juan', lastName: 'Ciscomani' },
    { firstName: 'Raul', lastName: 'Grijalva' },
    { firstName: 'Debbie', lastName: 'Lesko' },
    { firstName: 'Paul', lastName: 'Gosar' },
    { firstName: 'Eric', lastName: 'Crawford' },
    { firstName: 'Steve', lastName: 'Womack' },
    { firstName: 'Bruce', lastName: 'Westerman' },
    { firstName: 'Doug', lastName: 'LaMalfa' },
    { firstName: 'Jared', lastName: 'Huffman' },
    { firstName: 'Kevin', lastName: 'Kiley' },
    { firstName: 'Mike', lastName: 'Thompson' },
    { firstName: 'Tom', lastName: 'McClintock' },
    { firstName: 'Ami', lastName: 'Bera' },
    { firstName: 'Doris', lastName: 'Matsui' },
    { firstName: 'John', lastName: 'Garamendi' },
    { firstName: 'Josh', lastName: 'Harder' },
    { firstName: 'Mark', lastName: 'DeSaulnier' },
    { firstName: 'Nancy', lastName: 'Pelosi' },
    { firstName: 'Barbara', lastName: 'Lee' },
    { firstName: 'John', lastName: 'Duarte' },
    { firstName: 'Eric', lastName: 'Swalwell' },
    { firstName: 'Kevin', lastName: 'Mullin' },
    { firstName: 'Anna', lastName: 'Eshoo' },
    { firstName: 'Ro', lastName: 'Khanna' },
    { firstName: 'Zoe', lastName: 'Lofgren' },
    { firstName: 'Jimmy', lastName: 'Panetta' },
    { firstName: 'Vince', lastName: 'Fong' },
    { firstName: 'Jim', lastName: 'Costa' },
    { firstName: 'David', lastName: 'Valadao' },
    { firstName: 'Jay', lastName: 'Obernolte' },
    { firstName: 'Salud', lastName: 'Carbajal' },
    { firstName: 'Raul', lastName: 'Ruiz' },
    { firstName: 'Julia', lastName: 'Brownley' },
    { firstName: 'Mike', lastName: 'Garcia' },
    { firstName: 'Judy', lastName: 'Chu' },
    { firstName: 'Tony', lastName: 'Cardenas' },
    { firstName: 'Adam', lastName: 'Schiff' },
    { firstName: 'Grace', lastName: 'Napolitano' },
    { firstName: 'Brad', lastName: 'Sherman' },
    { firstName: 'Pete', lastName: 'Aguilar' },
    { firstName: 'Jimmy', lastName: 'Gomez' },
    { firstName: 'Norma', lastName: 'Torres' },
    { firstName: 'Ted', lastName: 'Lieu' },
    { firstName: 'Sydney', lastName: 'Dove' },
    { firstName: 'Linda', lastName: 'Sanchez' },
    { firstName: 'Mark', lastName: 'Takano' },
    { firstName: 'Young', lastName: 'Kim' },
    { firstName: 'Ken', lastName: 'Calvert' },
    { firstName: 'Robert', lastName: 'Garcia' },
    { firstName: 'Maxine', lastName: 'Waters' },
    { firstName: 'Nanette', lastName: 'Barragan' },
    { firstName: 'Michelle', lastName: 'Steel' },
    { firstName: 'Katie', lastName: 'Porter' },
    { firstName: 'Darrell', lastName: 'Issa' },
    { firstName: 'Mike', lastName: 'Levin' },
    { firstName: 'Scott', lastName: 'Peters' },
    { firstName: 'Sara', lastName: 'Jacobs' },
    { firstName: 'Juan', lastName: 'Vargas' },
    { firstName: 'Diana', lastName: 'DeGette' },
    { firstName: 'Joe', lastName: 'Neguse' },
    { firstName: 'Lauren', lastName: 'Boebert' },
    { firstName: 'Ken', lastName: 'Buck' },
    { firstName: 'Doug', lastName: 'Lamborn' },
    { firstName: 'Jason', lastName: 'Crow' },
    { firstName: 'Brittany', lastName: 'Pettersen' },
    { firstName: 'Yadira', lastName: 'Caraveo' },
    { firstName: 'John', lastName: 'Larson' },
    { firstName: 'Joe', lastName: 'Courtney' },
    { firstName: 'Rosa', lastName: 'DeLauro' },
    { firstName: 'James', lastName: 'Himes' },
    { firstName: 'Jahana', lastName: 'Hayes' },
    { firstName: 'Lisa Blunt', lastName: 'Rochester' },
    { firstName: 'Eleanor', lastName: 'Norton' },
    { firstName: 'Matt', lastName: 'Gaetz' },
    { firstName: 'Neal', lastName: 'Dunn' },
    { firstName: 'Kat', lastName: 'Cammack' },
    { firstName: 'Aaron', lastName: 'Bean' },
    { firstName: 'John', lastName: 'Rutherford' },
    { firstName: 'Michael', lastName: 'Waltz' },
    { firstName: 'Cory', lastName: 'Mills' },
    { firstName: 'Bill', lastName: 'Posey' },
    { firstName: 'Darren', lastName: 'Soto' },
    { firstName: 'Maxwell', lastName: 'Frost' },
    { firstName: 'Daniel', lastName: 'Webster' },
    { firstName: 'Gus', lastName: 'Bilirakis' },
    { firstName: 'Anna', lastName: 'Luna' },
    { firstName: 'Kathy', lastName: 'Castor' },
    { firstName: 'Laurel', lastName: 'Lee' },
    { firstName: 'Vern', lastName: 'Buchanan' },
    { firstName: 'Scott', lastName: 'Franklin' },
    { firstName: 'Byron', lastName: 'Donalds' },
    { firstName: 'Sheila', lastName: 'McCormick' },
    { firstName: 'Brian', lastName: 'Mast' },
    { firstName: 'Lois', lastName: 'Frankel' },
    { firstName: 'Jared', lastName: 'Moskowitz' },
    { firstName: 'Frederica', lastName: 'Wilson' },
    { firstName: 'Debbie', lastName: 'Schultz' },
    { firstName: 'Mario', lastName: 'Balart' },
    { firstName: 'Maria', lastName: 'Salazar' },
    { firstName: 'Carlos', lastName: 'Gimenez' },
    { firstName: 'Earl', lastName: 'Carter' },
    { firstName: 'Sanford', lastName: 'Bishop' },
    { firstName: 'Henry', lastName: 'Johnson' },
    { firstName: 'Nikema', lastName: 'Williams' },
    { firstName: 'Richard', lastName: 'McCormick' },
    { firstName: 'Lucy', lastName: 'McBath' },
    { firstName: 'Austin', lastName: 'Scott' },
    { firstName: 'Andrew', lastName: 'Clyde' },
    { firstName: 'Mike', lastName: 'Collins' },
    { firstName: 'Barry', lastName: 'Loudermilk' },
    { firstName: 'Rick', lastName: 'Allen' },
    { firstName: 'David', lastName: 'Scott' },
    { firstName: 'Marjorie', lastName: 'Greene' },
    { firstName: 'James', lastName: 'Moylan' },
    { firstName: 'Ed', lastName: 'Case' },
    { firstName: 'Jill', lastName: 'Tokuda' },
    { firstName: 'Russ', lastName: 'Fulcher' },
    { firstName: 'Michael', lastName: 'Simpson' },
    { firstName: 'Jonathan', lastName: 'Jackson' },
    { firstName: 'Robin', lastName: 'Kelly' },
    { firstName: 'Delia', lastName: 'Ramirez' },
    { firstName: 'Jesus', lastName: 'Garcia' },
    { firstName: 'Mike', lastName: 'Quigley' },
    { firstName: 'Sean', lastName: 'Casten' },
    { firstName: 'Danny', lastName: 'Davis' },
    { firstName: 'Raja', lastName: 'Krishnamoorthi' },
    { firstName: 'Janice', lastName: 'Schakowsky' },
    { firstName: 'Bradley', lastName: 'Schneider' },
    { firstName: 'Bill', lastName: 'Foster' },
    { firstName: 'Mike', lastName: 'Bost' },
    { firstName: 'Nikki', lastName: 'Budzinski' },
    { firstName: 'Lauren', lastName: 'Underwood' },
    { firstName: 'Mary', lastName: 'Miller' },
    { firstName: 'Darin', lastName: 'LaHood' },
    { firstName: 'Eric', lastName: 'Sorensen' },
    { firstName: 'Frank', lastName: 'Mrvan' },
    { firstName: 'Rudy', lastName: 'Yakym' },
    { firstName: 'Jim', lastName: 'Banks' },
    { firstName: 'James', lastName: 'Baird' },
    { firstName: 'Victoria', lastName: 'Spartz' },
    { firstName: 'Greg', lastName: 'Pence' },
    { firstName: 'Andre', lastName: 'Carson' },
    { firstName: 'Larry', lastName: 'Bucshon' },
    { firstName: 'Erin', lastName: 'Houchin' },
    { firstName: 'Mariannette', lastName: 'Meeks' },
    { firstName: 'Ashley', lastName: 'Hinson' },
    { firstName: 'Zachary', lastName: 'Nunn' },
    { firstName: 'Randy', lastName: 'Feenstra' },
    { firstName: 'Tracey', lastName: 'Mann' },
    { firstName: 'Jake', lastName: 'LaTurner' },
    { firstName: 'Sharice', lastName: 'Davids' },
    { firstName: 'Ron', lastName: 'Estes' },
    { firstName: 'James', lastName: 'Comer' },
    { firstName: 'Brett', lastName: 'Guthrie' },
    { firstName: 'Morgan', lastName: 'McGarvey' },
    { firstName: 'Thomas', lastName: 'Massie' },
    { firstName: 'Harold', lastName: 'Rogers' },
    { firstName: 'Andy', lastName: 'Barr' },
    { firstName: 'Steve', lastName: 'Scalise' },
    { firstName: 'Troy', lastName: 'Carter' },
    { firstName: 'Clay', lastName: 'Higgins' },
    { firstName: 'Mike', lastName: 'Johnson' },
    { firstName: 'Julia', lastName: 'Letlow' },
    { firstName: 'Garret', lastName: 'Graves' },
    { firstName: 'Chellie', lastName: 'Pingree' },
    { firstName: 'Jared', lastName: 'Golden' },
    { firstName: 'Andy', lastName: 'Harris' },
    { firstName: 'John', lastName: 'Sarbanes' },
    { firstName: 'Glenn', lastName: 'Ivey' },
    { firstName: 'Steny', lastName: 'Hoyer' },
    { firstName: 'David', lastName: 'Trone' },
    { firstName: 'Kweisi', lastName: 'Mfume' },
    { firstName: 'Jamie', lastName: 'Raskin' },
    { firstName: 'Richard', lastName: 'Neal' },
    { firstName: 'James', lastName: 'McGovern' },
    { firstName: 'Lori', lastName: 'Trahan' },
    { firstName: 'Jake', lastName: 'Auchincloss' },
    { firstName: 'Katherine', lastName: 'Clark' },
    { firstName: 'Seth', lastName: 'Moulton' },
    { firstName: 'Ayanna', lastName: 'Pressley' },
    { firstName: 'Stephen', lastName: 'Lynch' },
    { firstName: 'William', lastName: 'Keating' },
    { firstName: 'Jack', lastName: 'Bergman' },
    { firstName: 'John', lastName: 'Moolenaar' },
    { firstName: 'Hillary', lastName: 'Scholten' },
    { firstName: 'Bill', lastName: 'Huizenga' },
    { firstName: 'Tim', lastName: 'Walberg' },
    { firstName: 'Debbie', lastName: 'Dingell' },
    { firstName: 'Elissa', lastName: 'Slotkin' },
    { firstName: 'Daniel', lastName: 'Kildee' },
    { firstName: 'Lisa', lastName: 'McClain' },
    { firstName: 'John', lastName: 'James' },
    { firstName: 'Haley', lastName: 'Stevens' },
    { firstName: 'Rashida', lastName: 'Tlaib' },
    { firstName: 'Shri', lastName: 'Thanedar' },
    { firstName: 'Brad', lastName: 'Finstad' },
    { firstName: 'Angie', lastName: 'Craig' },
    { firstName: 'Dean', lastName: 'Phillips' },
    { firstName: 'Betty', lastName: 'McCollum' },
    { firstName: 'Ilhan', lastName: 'Omar' },
    { firstName: 'Tom', lastName: 'Emmer' },
    { firstName: 'Michelle', lastName: 'Fischbach' },
    { firstName: 'Pete', lastName: 'Stauber' },
    { firstName: 'Trent', lastName: 'Kelly' },
    { firstName: 'Bennie', lastName: 'Thompson' },
    { firstName: 'Michael', lastName: 'Guest' },
    { firstName: 'Mike', lastName: 'Ezell' },
    { firstName: 'Cori', lastName: 'Bush' },
    { firstName: 'Ann', lastName: 'Wagner' },
    { firstName: 'Blaine', lastName: 'Luetkemeyer' },
    { firstName: 'Mark', lastName: 'Alford' },
    { firstName: 'Emanuel', lastName: 'Cleaver' },
    { firstName: 'Sam', lastName: 'Graves' },
    { firstName: 'Eric', lastName: 'Burlison' },
    { firstName: 'Jason', lastName: 'Smith' },
    { firstName: 'Ryan', lastName: 'Zinke' },
    { firstName: 'Matthew', lastName: 'Rosendale' },
    { firstName: 'Mike', lastName: 'Flood' },
    { firstName: 'Don', lastName: 'Bacon' },
    { firstName: 'Adrian', lastName: 'Smith' },
    { firstName: 'Dina', lastName: 'Titus' },
    { firstName: 'Mark', lastName: 'Amodei' },
    { firstName: 'Susie', lastName: 'Lee' },
    { firstName: 'Steven', lastName: 'Horsford' },
    { firstName: 'Chris', lastName: 'Pappas' },
    { firstName: 'Ann', lastName: 'Kuster' },
    { firstName: 'Donald', lastName: 'Norcross' },
    { firstName: 'Jefferson', lastName: 'Drew' },
    { firstName: 'Andy', lastName: 'Kim' },
    { firstName: 'Christopher', lastName: 'Smith' },
    { firstName: 'Josh', lastName: 'Gottheimer' },
    { firstName: 'Frank', lastName: 'Pallone' },
    { firstName: 'Thomas', lastName: 'Kean' },
    { firstName: 'Robert', lastName: 'Menendez' },
    { firstName: 'Bill', lastName: 'Pascrell' },
    { firstName: 'Donald', lastName: 'Payne' },
    { firstName: 'Mikie', lastName: 'Sherrill' },
    { firstName: 'Bonnie', lastName: 'Coleman' },
    { firstName: 'Melanie', lastName: 'Stansbury' },
    { firstName: 'Gabe', lastName: 'Vasquez' },
    { firstName: 'Teresa', lastName: 'Fernandez' },
    { firstName: 'Nick', lastName: 'LaLota' },
    { firstName: 'Andrew', lastName: 'Garbarino' },
    { firstName: 'Thomas', lastName: 'Suozzi' },
    { firstName: 'Anthony', lastName: 'Esposito' },
    { firstName: 'Gregory', lastName: 'Meeks' },
    { firstName: 'Grace', lastName: 'Meng' },
    { firstName: 'Nydia', lastName: 'Velazquez' },
    { firstName: 'Hakeem', lastName: 'Jeffries' },
    { firstName: 'Yvette', lastName: 'Clarke' },
    { firstName: 'Daniel', lastName: 'Goldman' },
    { firstName: 'Nicole', lastName: 'Malliotakis' },
    { firstName: 'Jerrold', lastName: 'Nadler' },
    { firstName: 'Adriano', lastName: 'Espaillat' },
    { firstName: 'Alexandria', lastName: 'Ocasio-Cortez' },
    { firstName: 'Ritchie', lastName: 'Torres' },
    { firstName: 'Jamaal', lastName: 'Bowman' },
    { firstName: 'Michael', lastName: 'Lawler' },
    { firstName: 'Patrick', lastName: 'Ryan' },
    { firstName: 'Marcus', lastName: 'Molinaro' },
    { firstName: 'Paul', lastName: 'Tonko' },
    { firstName: 'Elise', lastName: 'Stefanik' },
    { firstName: 'Brandon', lastName: 'Williams' },
    { firstName: 'Nicholas', lastName: 'Langworthy' },
    { firstName: 'Claudia', lastName: 'Tenney' },
    { firstName: 'Joseph', lastName: 'Morelle' },
    { firstName: 'Timothy', lastName: 'Kennedy' },
    { firstName: 'Donald', lastName: 'Davis' },
    { firstName: 'Deborah', lastName: 'Ross' },
    { firstName: 'Gregory', lastName: 'Murphy' },
    { firstName: 'Valerie', lastName: 'Foushee' },
    { firstName: 'Virginia', lastName: 'Foxx' },
    { firstName: 'Kathy', lastName: 'Manning' },
    { firstName: 'David', lastName: 'Rouzer' },
    { firstName: 'Dan', lastName: 'Bishop' },
    { firstName: 'Richard', lastName: 'Hudson' },
    { firstName: 'Patrick', lastName: 'McHenry' },
    { firstName: 'Chuck', lastName: 'Edwards' },
    { firstName: 'Alma', lastName: 'Adams' },
    { firstName: 'Wiley', lastName: 'Nickel' },
    { firstName: 'Jeff', lastName: 'Jackson' },
    { firstName: 'Kelly', lastName: 'Armstrong' },
    { firstName: 'Gregorio', lastName: 'Sablan' },
    { firstName: 'Greg', lastName: 'Landsman' },
    { firstName: 'Brad', lastName: 'Wenstrup' },
    { firstName: 'Joyce', lastName: 'Beatty' },
    { firstName: 'Jim', lastName: 'Jordan' },
    { firstName: 'Robert', lastName: 'Latta' },
    { firstName: 'Bill', lastName: 'Johnson' },
    { firstName: 'Max', lastName: 'Miller' },
    { firstName: 'Warren', lastName: 'Davidson' },
    { firstName: 'Marcy', lastName: 'Kaptur' },
    { firstName: 'Michael', lastName: 'Turner' },
    { firstName: 'Shontel', lastName: 'Brown' },
    { firstName: 'Troy', lastName: 'Balderson' },
    { firstName: 'Emilia', lastName: 'Sykes' },
    { firstName: 'David', lastName: 'Joyce' },
    { firstName: 'Mike', lastName: 'Carey' },
    { firstName: 'Kevin', lastName: 'Hern' },
    { firstName: 'Josh', lastName: 'Brecheen' },
    { firstName: 'Frank', lastName: 'Lucas' },
    { firstName: 'Tom', lastName: 'Cole' },
    { firstName: 'Stephanie', lastName: 'Bice' },
    { firstName: 'Suzanne', lastName: 'Bonamici' },
    { firstName: 'Cliff', lastName: 'Bentz' },
    { firstName: 'Earl', lastName: 'Blumenauer' },
    { firstName: 'Val', lastName: 'Hoyle' },
    { firstName: 'Lori', lastName: 'DeRemer' },
    { firstName: 'Andrea', lastName: 'Salinas' },
    { firstName: 'Brian', lastName: 'Fitzpatrick' },
    { firstName: 'Brendan', lastName: 'Boyle' },
    { firstName: 'Dwight', lastName: 'Evans' },
    { firstName: 'Madeleine', lastName: 'Dean' },
    { firstName: 'Mary', lastName: 'Scanlon' },
    { firstName: 'Chrissy', lastName: 'Houlahan' },
    { firstName: 'Susan', lastName: 'Wild' },
    { firstName: 'Matt', lastName: 'Cartwright' },
    { firstName: 'Daniel', lastName: 'Meuser' },
    { firstName: 'Scott', lastName: 'Perry' },
    { firstName: 'Lloyd', lastName: 'Smucker' },
    { firstName: 'Summer', lastName: 'Lee' },
    { firstName: 'John', lastName: 'Joyce' },
    { firstName: 'Guy', lastName: 'Reschenthaler' },
    { firstName: 'Glenn', lastName: 'Thompson' },
    { firstName: 'Mike', lastName: 'Kelly' },
    { firstName: 'Christopher', lastName: 'Deluzio' },
    { firstName: 'Jenniffer', lastName: 'Colon' },
    { firstName: 'Gabe', lastName: 'Amo' },
    { firstName: 'Seth', lastName: 'Magaziner' },
    { firstName: 'Nancy', lastName: 'Mace' },
    { firstName: 'Joe', lastName: 'Wilson' },
    { firstName: 'Jeff', lastName: 'Duncan' },
    { firstName: 'William', lastName: 'Timmons' },
    { firstName: 'Ralph', lastName: 'Norman' },
    { firstName: 'Jim', lastName: 'Clyburn' },
    { firstName: 'Russell', lastName: 'Fry' },
    { firstName: 'Dusty', lastName: 'Johnson' },
    { firstName: 'Diana', lastName: 'Harshbarger' },
    { firstName: 'Tim', lastName: 'Burchett' },
    { firstName: 'Charles', lastName: 'Fleischmann' },
    { firstName: 'Scott', lastName: 'DesJarlais' },
    { firstName: 'Andrew', lastName: 'Ogles' },
    { firstName: 'John', lastName: 'Rose' },
    { firstName: 'Mark', lastName: 'Green' },
    { firstName: 'David', lastName: 'Kustoff' },
    { firstName: 'Steve', lastName: 'Cohen' },
    { firstName: 'Nathaniel', lastName: 'Moran' },
    { firstName: 'Dan', lastName: 'Crenshaw' },
    { firstName: 'Keith', lastName: 'Self' },
    { firstName: 'Pat', lastName: 'Fallon' },
    { firstName: 'Lance', lastName: 'Gooden' },
    { firstName: 'Jake', lastName: 'Ellzey' },
    { firstName: 'Lizzie', lastName: 'Fletcher' },
    { firstName: 'Morgan', lastName: 'Luttrell' },
    { firstName: 'Al', lastName: 'Green' },
    { firstName: 'Michael', lastName: 'McCaul' },
    { firstName: 'August', lastName: 'Pfluger' },
    { firstName: 'Kay', lastName: 'Granger' },
    { firstName: 'Ronny', lastName: 'Jackson' },
    { firstName: 'Randy', lastName: 'Weber' },
    { firstName: 'Monica', lastName: 'Cruz' },
    { firstName: 'Veronica', lastName: 'Escobar' },
    { firstName: 'Pete', lastName: 'Sessions' },
    { firstName: 'Sheila', lastName: 'Lee' },
    { firstName: 'Jodey', lastName: 'Arrington' },
    { firstName: 'Joaquin', lastName: 'Castro' },
    { firstName: 'Chip', lastName: 'Roy' },
    { firstName: 'Troy', lastName: 'Nehls' },
    { firstName: 'Tony', lastName: 'Gonzales' },
    { firstName: 'Beth', lastName: 'Duyne' },
    { firstName: 'Roger', lastName: 'Williams' },
    { firstName: 'Michael', lastName: 'Burgess' },
    { firstName: 'Michael', lastName: 'Cloud' },
    { firstName: 'Henry', lastName: 'Cuellar' },
    { firstName: 'Sylvia', lastName: 'Garcia' },
    { firstName: 'Jasmine', lastName: 'Crockett' },
    { firstName: 'John', lastName: 'Carter' },
    { firstName: 'Colin', lastName: 'Allred' },
    { firstName: 'Marc', lastName: 'Veasey' },
    { firstName: 'Vicente', lastName: 'Gonzalez' },
    { firstName: 'Greg', lastName: 'Casar' },
    { firstName: 'Brian', lastName: 'Babin' },
    { firstName: 'Lloyd', lastName: 'Doggett' },
    { firstName: 'Wesley', lastName: 'Hunt' },
    { firstName: 'Blake', lastName: 'Moore' },
    { firstName: 'Celeste', lastName: 'Maloy' },
    { firstName: 'John', lastName: 'Curtis' },
    { firstName: 'Burgess', lastName: 'Owens' },
    { firstName: 'Becca', lastName: 'Balint' },
    { firstName: 'Robert', lastName: 'Wittman' },
    { firstName: 'Jennifer', lastName: 'Kiggans' },
    { firstName: 'Bobby', lastName: 'Scott' },
    { firstName: 'Jennifer', lastName: 'McClellan' },
    { firstName: 'Bob', lastName: 'Good' },
    { firstName: 'Ben', lastName: 'Cline' },
    { firstName: 'Abigail', lastName: 'Spanberger' },
    { firstName: 'Donald', lastName: 'Beyer' },
    { firstName: 'Jennifer', lastName: 'Wexton' },
    { firstName: 'Gerry', lastName: 'Connolly' },
    { firstName: 'Stacey', lastName: 'Plaskett' },
    { firstName: 'Suzan', lastName: 'DelBene' },
    { firstName: 'Rick', lastName: 'Larsen' },
    { firstName: 'Marie', lastName: 'Perez' },
    { firstName: 'Dan', lastName: 'Newhouse' },
    { firstName: 'Cathy', lastName: 'Rodgers' },
    { firstName: 'Derek', lastName: 'Kilmer' },
    { firstName: 'Pramila', lastName: 'Jayapal' },
    { firstName: 'Kim', lastName: 'Schrier' },
    { firstName: 'Adam', lastName: 'Smith' },
    { firstName: 'Marilyn', lastName: 'Strickland' },
    { firstName: 'Carol', lastName: 'Miller' },
    { firstName: 'Alexander', lastName: 'Mooney' },
    { firstName: 'Bryan', lastName: 'Steil' },
    { firstName: 'Mark', lastName: 'Pocan' },
    { firstName: 'Derrick', lastName: 'Orden' },
    { firstName: 'Gwen', lastName: 'Moore' },
    { firstName: 'Scott', lastName: 'Fitzgerald' },
    { firstName: 'Glenn', lastName: 'Grothman' },
    { firstName: 'Thomas', lastName: 'Tiffany' },
    { firstName: 'Mike', lastName: 'Gallagher' },
    { firstName: 'Harriet', lastName: 'Hageman' }
];



const getIssueDetails = (lastName) => {
    let details = {};
    representativeList.forEach((representative) => {
        const representativeKey = representative.lastName.toLowerCase().replace(/ /g, '_');
        details[representativeKey] = {
            title: `Media Monitoring: Rep. ${representative.firstName} ${representative.lastName}`,
            filters: { persons: [`${representative.firstName} ${representative.lastName}`], countries: ["United States"]  }
        };
    });

    return details[lastName.toLowerCase().replace(/ /g, '_')] || null;
};

const RepresentativeTracker = () => {
    let { issue } = useParams();
    const issueDetails = getIssueDetails(issue);
    const { user } = useUser();

    if (!issueDetails) {
        return <h1 style={{ textAlign: "center" }}>Issue Not Found</h1>;
    }

    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>{issueDetails.title}</h1>
            {!user && (
                <Alert style={{ backgroundColor: '#222222', color: '#F8F9FA', padding: '10px 20px' }} className="text-center">
                    Sign in to VerbaAI to access additional features, including bookmarking
                    speeches and creating custom trackers.
                </Alert>
            )}
            <SearchableTable 
            key={issue} 
            baseRequest={issueDetails.filters} 
            excludePast24Hours={true} 
            countrySearch={false} 
            languageSearch={false} 
            mediaMonitor={true} 
            personOfInterest={issueDetails.filters.persons[0]} 
            trackerType={["current congress"]}
            />
        </div>
    );
};

export default RepresentativeTracker;
