import '../GlobalStyles.css'; // Import the CSS file
import React, { useState, useEffect, useContext } from "react";
import "../GlobalStyles.css";
import SearchableTable from '../search/SearchableTable.js';
import { useUser } from "../../contexts/UserContext.js";
import { Tabs, Tab, Alert } from 'react-bootstrap';


const BofATracker = () => {

    const filters = {
        "tracker_type": ["bofa"]
    }

    const bofaOrgDict  = {
        "AMD": "AMD",
        "$AMD": "AMD",
        "NYSE: AMD": "AMD",
        "NYSE:AMD": "AMD",
        "Advanced Micro Devices": "AMD",
        "CAVA": "CAVA",
        "Cava": "CAVA",
        "$CAVA": "CAVA",
        "NYSE: CAVA": "CAVA",
        "NYSE:CAVA": "CAVA",
        "NYSE: BAC":"BAC",
        "NYSE:BAC":"BAC",
        "$BAC":"BAC",
        "Bank of America":"BAC",
        "SBUX":"SBUX",
        "$SBUX":"SBUX",
        "NYSE: SBUX": "SBUX",
        "NYSE:SBUX": "SBUX",
        "Starbucks":"SBUX",
        "SPGI":"SPGI",
        "S&P Global":"SPGI",
        "$SPGI":"SPGI",
        "NYSE: SPGI":"SPGI",
        "NYSE:SPGI":"SPGI"
    }

    const customOrgOptions = [
        { label: 'AMD', value: 'AMD' },
        { label: 'BAC', value: 'BAC' },
        { label: 'CAVA', value: 'CAVA' },
        { label: 'SBUX', value: 'SBUX' },
        { label: 'SPGI', value: 'SPGI' }
    ];

    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>Portfolio Monitoring Demo</h1>
            <Tabs defaultActiveKey="mentions" id="uncontrolled-tab-example" className="border-3">
                <Tab eventKey="mentions" title="Media Mentions">
                    <SearchableTable baseRequest={filters} mediaMonitor={true} orgsOfInterest={bofaOrgDict} showOrgs={true} orgOptions={customOrgOptions} excludePast24Hours={true} trackerType={["bofa"]}/>
                </Tab>
                <Tab eventKey="about" title="About">
                    <br />
                    <h4 style={{ textAlign: 'center' }}>
                        This tracker monitors 230,000+ outlets in real-time for mentions of portfolio tickers/companies of interest.
                    </h4>
                </Tab>
            </Tabs>
        </div>
    );
};

export default BofATracker;
