import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import './Search.css';
import '../GlobalStyles.css';
import { IoMdRefresh } from "react-icons/io";
import { FaTrash } from "react-icons/fa";
import CustomCheckbox from './CustomCheckbox';

// const SideBar = () => {

const SideBar = ({
    sourceOptions, selectedSource, onSourceChange,
    orgOptions, selectedOrg, onOrgChange, showOrgs,
    languageOptions, selectedLanguage, onLanguageChange, languageSearch = true,
    countryOptions, selectedCountry, onCountryChange, countrySearch = true,
    topicOptions, selectedTopics, onTopicChange,
    timeRangeOptions, selectedTimeRange, onTimeRangeChange,
    startDate, onStartDateChange, endDate, onEndDateChange,
    dateError, selectedSemanticSearch, onSemanticSearchChange,
    customTrackerCreator, trackerName, onTrackerNameChange, trackerNameError,
    handleSearchClick, handleCreateTrackerClick, handleRefreshClick,
    sentimentOptions, selectedSentiments, onSentimentChange, showSentiment,
    clusterSortOptions, onClusterSortChange, selectedClusterSort,
    clusterSearch = false,
    personOptions, selectedPersons, onPersonChange, congressTracker = false,
    electionFactsTracker = false, selectedFactCheckType, onFactCheckChange, factCheckTypeOptions
}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 768);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const [showSentimentCheckbox, setShowSentimentCheckbox] = useState(false);
    const [showPoliticalTiltCheckbox, setShowPoliticalTiltCheckbox] = useState(false);
    const [showClusterCheckbox, setShowClusterCheckbox] = useState(false);


    const DropdownIndicator = () => null;

    const [isMinimized, setIsMinimized] = useState(() => isMobile); //Track sidebar visibility

    const toggleSidebar = () => {
        setIsMinimized(!isMinimized);
    };

    return (
        <div className={"sidebar fixed-width"}>
            {isMobile && (
                <button
                    className="btn-sidebar btn-charcoal-gray mb-2"
                    onClick={toggleSidebar}
                    style={
                        isMinimized ? {
                            // display: 'block', // Makes the button a block element
                            // marginLeft: 'auto', // Auto margin on the left
                            // marginRight: 'auto', // Auto margin on the right
                            // width: 'fit-content' // Adjusts the width to fit the content
                            width: '100%'
                        } : {
                            // display: 'block', // Makes the button a block element
                            // marginLeft: 'auto', // Auto margin on the left
                            // marginRight: 0, // No margin on the right
                            // width: 'fit-content' // Adjusts the width to fit the content
                            width: '100%'

                        }
                    }
                >
                    Show Search Filters
                    {/* {isMinimized ? 'Show Search Filters' : 'Hide'} */}
                </button>
            )}


            {/* Only render the rest of the sidebar content if not minimized */}
            {!isMinimized && (
                <>
                    <div className="search-buttons">
                        <button
                            className="btn-circle btn-mid-gray mb-2 mr-1"
                            onClick={handleRefreshClick}
                            disabled={clusterSearch ? false : dateError !== ''}
                            style={{
                                backgroundColor: (clusterSearch ? '' : dateError !== '') ? '#ccc' : '',
                                color: (clusterSearch ? '' : dateError !== '') ? '#666' : '',
                            }}
                        >
                            <IoMdRefresh />
                        </button>
                        <button
                            className="btn-sidebar btn-charcoal-gray mb-2 mr-1"
                            onClick={handleSearchClick}
                            disabled={clusterSearch ? false : dateError !== ''}
                            style={{
                                backgroundColor: (clusterSearch ? '' : dateError !== '') ? '#ccc' : '',
                                color: (clusterSearch ? '' : dateError !== '') ? '#666' : '',
                            }}
                        >
                            {customTrackerCreator ? 'Preview' : 'Search'}
                        </button>
                        {/* Refresh Button */}

                        {customTrackerCreator && (
                            <button
                                className="btn-sidebar btn-charcoal-gray mb-2"
                                onClick={handleCreateTrackerClick}
                                disabled={dateError !== '' || trackerNameError !== ''}
                                style={{
                                    backgroundColor: (dateError !== '' || trackerNameError !== '') ? '#ccc' : '', // Red color for disabled state
                                    color: (dateError !== '' || trackerNameError !== '') ? '#666' : '', // Darker text color for disabled state
                                    // Add other styling as needed
                                }}
                            >
                                Create
                            </button>
                        )}
                    </div>
                    <div className="date-picker">
                        {customTrackerCreator && (
                            <input
                                type="text"
                                placeholder="Tracker Name"
                                value={trackerName}
                                onChange={onTrackerNameChange}
                                className="semantic-search-bar"
                            />
                        )}
                        {customTrackerCreator && trackerNameError && <div className="error-message">{trackerNameError}</div>}
                    </div>

                    {/* Semantic Search Input */}
                    <div className="date-picker">
                        <input
                            type="text"
                            placeholder="AI-Powered Search"
                            value={selectedSemanticSearch}
                            onChange={onSemanticSearchChange}
                            className="semantic-search-bar"
                        />
                    </div>


                    {showOrgs && (
                        <div className="dropdown">
                            <Select
                                options={orgOptions}
                                onChange={onOrgChange}
                                value={selectedOrg}
                                isMulti
                                isSearchable
                                isClearable
                                placeholder={"Organizations"}
                            />
                        </div>
                    )}

                    {/* Sources Dropdown */}
                    {!clusterSearch && (
                        <div className="dropdown">
                            <Select
                                options={sourceOptions}
                                onChange={onSourceChange}
                                value={selectedSource}
                                isMulti
                                isSearchable
                                placeholder="Sources"
                                styles={{
                                    menu: (provided) => ({
                                        ...provided,
                                        overflowX: 'hidden',
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        whiteSpace: 'normal',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }),
                                }}
                            />
                        </div>
                    )}


                    {languageSearch && !clusterSearch && (
                        <div className="dropdown">
                            <Select
                                options={languageOptions}
                                onChange={onLanguageChange}
                                value={selectedLanguage}
                                isMulti
                                isSearchable
                                placeholder="Languages"
                            />
                        </div>
                    )}

                    {countrySearch && (
                        <div className="dropdown">
                            <Select
                                options={countryOptions}
                                onChange={onCountryChange}
                                value={selectedCountry}
                                isMulti
                                isSearchable
                                isClearable
                                placeholder={clusterSearch ? "Country" : "Countries"}
                            />
                        </div>
                    )}

                    {showSentiment && (
                        <div className="dropdown">
                            <Select
                                options={sentimentOptions}
                                onChange={onSentimentChange}
                                value={selectedSentiments}
                                isMulti
                                isSearchable={false}
                                placeholder="Sentiment"
                            />
                        </div>
                    )}

                    <div className="dropdown">
                        <Select
                            options={topicOptions}
                            onChange={onTopicChange}
                            value={selectedTopics}
                            isMulti
                            isSearchable
                            placeholder="Topics"
                        />
                    </div>

                    {clusterSearch && (
                        <div className="dropdown">
                            <Select
                                options={clusterSortOptions}
                                onChange={onClusterSortChange}
                                value={selectedClusterSort}
                                isMulti={false}
                                isSearchable={false}
                                placeholder="Sort By"
                            />
                        </div>
                    )}

                    {congressTracker && (
                        <div className="dropdown">
                            <Select
                                options={personOptions}
                                onChange={onPersonChange}
                                value={selectedPersons}
                                isMulti
                                isSearchable
                                placeholder="Person"
                            />
                        </div>
                    )}

                    {electionFactsTracker && (
                        <div className="dropdown">
                            <Select
                                options={factCheckTypeOptions}
                                onChange={onFactCheckChange}
                                value={selectedFactCheckType}
                                isMulti
                                isSearchable
                                placeholder="Fact Check Subject"
                            />
                        </div>
                    )}

                    {!customTrackerCreator && (
                        <>
                            <div className="dropdown">
                                <Select
                                    options={timeRangeOptions}
                                    onChange={onTimeRangeChange}
                                    value={selectedTimeRange}
                                    isSearchable={false}
                                />
                            </div>
                            {selectedTimeRange && selectedTimeRange.value === "Custom Range" && (
                                <>
                                    <div className="date-picker">
                                        <input
                                            type="text"
                                            placeholder="Start: MM/DD/YYYY"
                                            value={startDate}
                                            onChange={onStartDateChange}
                                            pattern="\d{2}/\d{2}/\d{4}"
                                            title="Enter date in MM/DD/YYYY format"
                                        />
                                    </div>
                                    <div className="date-picker">
                                        <input
                                            type="text"
                                            placeholder="End: MM/DD/YYYY"
                                            value={endDate}
                                            onChange={onEndDateChange}
                                            pattern="\d{2}/\d{2}/\d{4}"
                                            title="Enter date in MM/DD/YYYY format"
                                        />
                                    </div>
                                </>
                            )}
                            {dateError && <div className="error-message">{dateError}</div>}
                        </>

                    )}
                    {/* {!clusterSearch && (
                        <div className="checkbox-group">
                            <CustomCheckbox
                                label="Show Sentiment"
                                checked={showSentimentCheckbox}
                                onChange={setShowSentimentCheckbox}
                            />
                            <CustomCheckbox
                                label="Show Political Tilt"
                                checked={showPoliticalTiltCheckbox}
                                onChange={setShowPoliticalTiltCheckbox}
                            />
                            <CustomCheckbox
                                label="Show Narrative"
                                checked={showClusterCheckbox}
                                onChange={setShowClusterCheckbox}
                            />
                        </div>
                    )} */}
                </>
            )}
        </div>
    );
};

export default SideBar;