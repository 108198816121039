import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SearchableTable, { getDatePublished, getTimePublished } from '../search/SearchableTable.js';
import GridLoader from 'react-spinners/GridLoader';
import '../GlobalStyles.css';
import '../search/Search.css';
import DataTableCluster from '../search/DataTableCluster.js';
import { Tab, Tabs, Badge } from 'react-bootstrap';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import { Line, Scatter } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import SideBar from '../search/SideBar.js';
import DataTable from '../search/DataTable.js';
import NarrativeHistory from './NarrativeHistory.js';
import ClusterSentiment from '../visualizations/ClusterSentiment.js';
import ClusterSimilarity from '../visualizations/ClusterSimilarity.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    annotationPlugin
);


export function getTimezoneName(utcDateString) {
    const date = new Date(utcDateString);
    const timezoneName = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).format(date).split(', ')[1];
    return timezoneName;
}


function NarrativeAnalysis({ baseRequest, customTrackerCreator = false, countrySearch = true, countryColumn = true, languageSearch = true, languageColumn = true, sourceOptions: propSourceOptions, preset_query = "", excludePast24Hours = false, showSentiment = false, fringeNarrative = false }) {


    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 768);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [clusterData, setClusterData] = useState([]); //refers to cluster table data
    const [allArticleData, setAllArticleData] = useState([]); //set once on initial load
    const [articleData, setArticleData] = useState([]); //reflects current query
    const [noResults, setNoResults] = useState(false);
    const [articleIds, setArticleIds] = useState([]);
    const [clusterDataIsLoading, setClusterDataIsLoading] = useState(true);
    const [articleDataIsLoading, setArticleDataIsLoading] = useState(true);
    const [clusterTimestamps, setClusterTimestamps] = useState([]);
    const [articleTimestamps, setArticleTimestamps] = useState([]);
    const [timeSeriesData, setTimeSeriesData] = useState([]);
    const [articleFilters, setArticleFilters] = useState(null);
    const [articleTsneDict, setArticleTsneDict] = useState({});
    const [clusterCentroidTsne, setClusterCentroidTsne] = useState([]);

    // // State to track if the initial load is dones
    const [initialLoadDone, setInitialLoadDone] = useState(false);

    // Function to fetch latest data on initial load
    useEffect(() => {
        if (articleFilters && !initialLoadDone) {
            fetchArticleData();
            setInitialLoadDone(true);
        }
    }, [articleFilters, initialLoadDone]);


    const fullUrl = window.location.href;
    const cluster_id = fullUrl.split("/")[4];
    // const baseUrl = window.location.origin + "/narrative/";
    // const cluster_id = fullUrl.includes(baseUrl) ? fullUrl.split(baseUrl)[1] : null;

    const clusterFilters = {
        query: "",
        sources: [],
        countries: [],
        topics: [],
        keywords: [],
        date_published: [null, null],
        links: [],
        tracker_type: [],
        cluster: true,
        article_id: [cluster_id],
        latest: true,
        fringe: fringeNarrative
    };

    useEffect(() => {
        if (articleIds.length > 0) {
            setArticleFilters({
                query: "",
                sources: [],
                countries: [],
                topics: [],
                date_published: [],
                links: [],
                tracker_type: [],
                article_id: articleIds,
                latest: true,
                fringe: fringeNarrative
            });
        }
    }, [articleIds]);

    useEffect(() => {
        fetchClusterData();
    }, []); // This will call fetchClusterData on component mount

    useEffect(() => {
        setClusterDataIsLoading(clusterData.length === 0);
    }, [clusterData]);

    useEffect(() => {
        setArticleDataIsLoading(articleData.length === 0);
    }, [articleData]);


    const fetchClusterData = async () => {
        try {

            console.log("L130 query is", clusterFilters)

            const response = await axios.post('https://fopotracking.ue.r.appspot.com/search', clusterFilters);

            setArticleIds(response['data'][0]['source']['Article IDs'])

            let records = [];
            let clusterTimestamps = [];
            let articleTsneDict = {};

            response.data.forEach(item => {
                const { date_published, link, word_count, title, source_url, country, language, website_name, country_rank, site_rank, topics, locations, organizations, persons, summary } = item.source;
                const uniqueId = item.id; // Adjust this according to your response structure
                let date = new Date(item.source['Update timestamp']);
                console.log("l110 item is", item);

                // Add article embeddings to article_tsne dictionary
                item.source['article_embeddings_reduced'].forEach(article => {
                    articleTsneDict[article.ID] = article.embedding;
                });
                setClusterCentroidTsne(item.source['cluster_summary_embedding_reduced']);

                const record = {
                    "cluster_id": item.id,
                    "current_title": item.source['Cluster title'].replace(/\*/g, ''), // Removes all asterisks
                    "current_summary": item.source['Cluster summary'].replace(/\*/g, ''), // Removes all asterisks
                    "creation_timestamp_utc": item.source['Creation timestamp'].replace(" ", "T") + "+00:00",
                    "update_timestamp_utc": item.source['Update timestamp'].replace(" ", "T") + "+00:00",
                    "article_ids": item.source['Article IDs'],
                    "cluster_size": item.source['Cluster size'],
                    "title_history": item.source['Title history'],
                    "summary_history": item.source['Summary history']
                };
                records.push(record);

                // Extract timestamps from the title history and add to allTimestamps array
                if (record.title_history) {
                    record.title_history.forEach(historyItem => {
                        clusterTimestamps.push(historyItem.timestamp.replace(" ", "T") + "+00:00");
                    });
                }
            });
            setClusterData(records);
            setClusterTimestamps(clusterTimestamps);
            // console.log("cluster timestamps are", clusterTimestamps);
            console.log("cluster data is", records)
            setArticleTsneDict(articleTsneDict);
        }
        catch (error) {
            console.error('Error fetching latest cluster data:', error);
        }
    };

    const fetchArticleData = async () => {
        try {
            console.log("L133 query is", articleFilters)

            const response = await axios.post('https://fopotracking.ue.r.appspot.com/search', articleFilters);

            let records = [];
            let articleTimestamps = [];  // Array to collect article timestamps

            response['data'].forEach(item => {
                const { date_published, link, word_count, title, source_url, country, language, website_name, country_rank, site_rank, topics, locations, organizations, persons, summary, sentiment_compound, charged } = item['source'];
                const uniqueId = item['id']; // Adjust this according to your response structure
                // console.log("UNIQUE ID: ", uniqueId)
                let date = new Date(item['source']['date_published']);

                records.push({
                    "article_id": uniqueId, // Add the unique ID here
                    "date_time_published_utc": item['source']['date_published'].replace(" ", "T") + "+00:00",
                    "date_published": getDatePublished(item['source']['date_published'].replace(" ", "T") + "+00:00"),
                    "time_published": getTimePublished(item['source']['date_published'].replace(" ", "T") + "+00:00"),
                    "timezone_name": new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).format(date).split(', ')[1],
                    "link": item['source']['link'],
                    "word_count": word_count,
                    "title": title,
                    "source_url": source_url,
                    "country": country,
                    "language": language,
                    "source": website_name,
                    "country_rank": country_rank,
                    "site_rank": site_rank,
                    "topics": topics,
                    "locations": locations,
                    "organizations": organizations,
                    "persons": persons,
                    "summary": summary,
                    "image_link": item['source']['image'] || null,
                    "sentiment_compound": sentiment_compound || null, // Provide a default value if compound_sentiment is missing
                    "charged": charged || null //Default to No if compound_sentiment is missing
                });
                articleTimestamps.push(item['source']['date_published'].replace(" ", "T") + "+00:00");  // Collect the timestamp
            });

            // Used for options querying
            if (!initialLoadDone) {
                setAllArticleData(records);
            }

            setArticleData(records);
            if (records.length === 0) {
                setNoResults(true);
            } else {
                setNoResults(false);
            }
            setArticleTimestamps(articleTimestamps);  // Update the state with the collected timestamps
            // console.log("L192", articleData)
        }
        catch (error) {
            console.error('Error fetching latest article data:', error);
        }
    };


    // Data for Visualizations

    const propagateTimeSeriesData = () => {
        let timeSeriesData = {};

        // Ensure timestamps are sorted
        let sortedClusterTimestamps = [...clusterTimestamps].sort((a, b) => new Date(a) - new Date(b));

        // Initialize the dictionary with each timestamp
        sortedClusterTimestamps.forEach((timestamp, index) => {
            timeSeriesData[timestamp] = [];
        });

        // Map articles to the appropriate timestamp range
        articleData.forEach(article => {
            let articleTimestamp = new Date(article.date_time_published_utc);

            for (let i = 0; i < sortedClusterTimestamps.length; i++) {
                let currentClusterTimestamp = new Date(sortedClusterTimestamps[i]);

                // Check if the article falls between the current timestamp and the next one
                if (i === 0 && articleTimestamp < currentClusterTimestamp) {
                    // Special case for articles published before the first timestamp
                    if (!timeSeriesData["Before First Timestamp"]) {
                        timeSeriesData["Before First Timestamp"] = [];
                    }
                    timeSeriesData["Before First Timestamp"].push(article.article_id);
                    break;
                } else if (articleTimestamp >= currentClusterTimestamp && (i === sortedClusterTimestamps.length - 1 || articleTimestamp < new Date(sortedClusterTimestamps[i + 1]))) {
                    // Article falls within the current cluster timestamp range
                    timeSeriesData[sortedClusterTimestamps[i]].push(article.article_id);
                    break;
                }
            }
        });

        setTimeSeriesData(timeSeriesData);
    };

    // Call this function whenever appropriate, likely after data fetching or updates
    useEffect(() => {
        if (articleData.length > 0 && clusterTimestamps.length > 0) {
            propagateTimeSeriesData();
        }
    }, [articleData, clusterTimestamps]); // Dependencies to re-run this effect when article data or cluster timestamps update


    const mapSentiment = (score) => {
        if (score <= -0.6) return { text: "Strongly Negative", color: '#DE0A26', textColor: "#FFFFFF" }; // Bright red
        if (score > -0.6 && score <= -0.2) return { text: "Moderately Negative", color: '#F7B4BB', textColor: "#222222" }; // Pale red
        if (score > -0.2 && score <= 0.2) return { text: "Neutral", color: '#CCCCCC', textColor: "#222222" }; // Gray
        if (score > 0.2 && score <= 0.6) return { text: "Moderately Positive", color: '#B5FE89', textColor: "#222222" }; // Pale green
        if (score > 0.6) return { text: "Strongly Positive", color: '#44B200', textColor: "#FFFFFF" }; // Bright green
    };

    const prepareChartDataArticleSentiments = () => {
        const dataPoints = [];

        articleData.forEach(article => {
            const sentiment = mapSentiment(article.sentiment_compound);
            dataPoints.push({
                x: new Date(article.date_time_published_utc), // Ensure dates are correctly parsed
                y: article.sentiment_compound, // Use the sentiment_compound field for the y-axis
                title: article.title, // Include the article title
                source: article.source,
                country: article.country,
                language: article.language,
                article_id: article.article_id,
                backgroundColor: sentiment.color,
                borderColor: "#222222"
            });
        });

        return dataPoints;
    };

    // Analytics    

    const getTopSources = (articleData) => {
        // Create a dictionary to store source data with counts and associated country
        const sourceInfo = articleData.reduce((accumulator, article) => {
            const { source, country } = article;
            if (source) {
                if (accumulator[source]) {
                    accumulator[source].count += 1;  // Increment the count if source already exists
                } else {
                    accumulator[source] = { country, count: 1 };  // Initialize if source is new
                }
            }
            return accumulator;
        }, {});

        // Convert the dictionary to an array, sort by count in descending order, and get the top 5
        const sortedSourceArray = Object.entries(sourceInfo)
            .map(([key, value]) => ({
                source: key,
                country: value.country,
                count: value.count
            }))
            .sort((a, b) => b.count - a.count)
            .slice(0, 5);  // Select the top 5 sources based on article count

        // Convert the array back to a dictionary format for the final output
        const topSources = sortedSourceArray.reduce((acc, item) => {
            acc[item.source] = {
                country: item.country,
                count: item.count
            };
            return acc;
        }, {});

        return topSources;
    };

    const getTopCountries = (articleData) => {
        // Create a dictionary to store country data with counts
        const countryInfo = articleData.reduce((accumulator, article) => {
            const { country } = article;
            if (country) {
                if (accumulator[country]) {
                    accumulator[country] += 1;  // Increment the count if country already exists
                } else {
                    accumulator[country] = 1;  // Initialize if country is new
                }
            }
            return accumulator;
        }, {});

        // Convert the dictionary to an array, sort by count in descending order, and get the top 5
        const sortedCountryArray = Object.entries(countryInfo)
            .map(([key, value]) => ({
                country: key,
                count: value
            }))
            .sort((a, b) => b.count - a.count)
            .slice(0, 5);  // Select the top 5 countries based on article count

        // Convert the array back to a dictionary format for the final output
        const topCountries = sortedCountryArray.reduce((acc, item) => {
            acc[item.country] = item.count;
            return acc;
        }, {});

        return topCountries;
    };

    const getTopLanguages = (articleData) => {
        // Create a dictionary to store language data with counts
        const languageCount = articleData.reduce((accumulator, article) => {
            const { language } = article;
            if (language) {
                if (accumulator[language]) {
                    accumulator[language] += 1;  // Increment the count if the language already exists
                } else {
                    accumulator[language] = 1;  // Initialize if the language is new
                }
            }
            return accumulator;
        }, {});

        // Convert the dictionary to an array, sort by count in descending order
        const sortedLanguages = Object.entries(languageCount)
            .map(([key, value]) => ({
                language: key,
                count: value
            }))
            .sort((a, b) => b.count - a.count);

        // Slice the array to get only the top 5 languages
        const topFiveLanguages = sortedLanguages.slice(0, 5);

        // Convert the array back to a dictionary format for the final output
        const topLanguages = {};
        topFiveLanguages.forEach(item => {
            topLanguages[item.language] = item.count;
        });

        return topLanguages;
    };


    const getTopLocations = (articleData) => {
        // Create a dictionary to store location data with counts
        const locationCount = articleData.reduce((accumulator, article) => {
            const { locations } = article;
            if (locations && Array.isArray(locations)) {
                const uniqueLocations = new Set(locations);  // Use a Set to ensure each location is counted once per article
                uniqueLocations.forEach(location => {
                    if (accumulator[location]) {
                        accumulator[location] += 1;  // Increment the count if location already exists
                    } else {
                        accumulator[location] = 1;  // Initialize if location is new
                    }
                });
            }
            return accumulator;
        }, {});

        // Convert the dictionary to an array, sort by count in descending order, and get the top 5
        const sortedLocations = Object.entries(locationCount)
            .map(([location, count]) => ({
                location,
                count
            }))
            .sort((a, b) => b.count - a.count)
            .slice(0, 5);  // Select the top 5 locations based on article count

        // Convert the array back to a dictionary format for the final output
        const topLocations = sortedLocations.reduce((acc, item) => {
            acc[item.location] = item.count;
            return acc;
        }, {});

        return topLocations;
    };


    const getTopOrganizations = (articleData) => {
        // Create a dictionary to store organization data with counts
        const organizationCount = articleData.reduce((accumulator, article) => {
            const { organizations } = article;
            if (organizations && organizations.length > 0) {
                organizations.forEach(org => {
                    if (accumulator[org]) {
                        accumulator[org] += 1;  // Increment the count for each mention
                    } else {
                        accumulator[org] = 1;  // Initialize if this is the first mention of the organization
                    }
                });
            }
            return accumulator;
        }, {});

        // Convert the dictionary to an array, sort by count in descending order, and get the top 5
        const sortedOrganizationsArray = Object.entries(organizationCount)
            .map(([key, value]) => ({
                organization: key,
                count: value
            }))
            .sort((a, b) => b.count - a.count)
            .slice(0, 5);  // Select the top 5 organizations based on article count

        // Convert the array back to a dictionary format for the final output
        const topOrganizations = sortedOrganizationsArray.reduce((acc, item) => {
            acc[item.organization] = item.count;
            return acc;
        }, {});

        return topOrganizations;
    };



    const getTopPersons = (articleData) => {
        // Create a dictionary to store person data with article counts
        const personCount = articleData.reduce((accumulator, article) => {
            const { persons } = article;
            if (persons && Array.isArray(persons)) {
                persons.forEach(person => {
                    if (accumulator[person]) {
                        accumulator[person] += 1; // Increment the count for each mention
                    } else {
                        accumulator[person] = 1; // Initialize if person is new
                    }
                });
            }
            return accumulator;
        }, {});

        // Convert the dictionary to an array, sort by count in descending order, and get the top 5
        const sortedPersonsArray = Object.entries(personCount)
            .map(([name, count]) => ({
                name,
                count
            }))
            .sort((a, b) => b.count - a.count)
            .slice(0, 5); // Select the top 5 persons based on article count

        // Convert the array back to a dictionary format for the final output
        const topPersons = sortedPersonsArray.reduce((acc, item) => {
            acc[item.name] = item.count;
            return acc;
        }, {});

        return topPersons;
    };

    const calculateAverageSentiment = (articleData) => {
        if (articleData.length === 0) {
            return 0; // Return 0 if there are no articles
        }

        const totalSentiment = articleData.reduce((sum, article) => {
            return sum + article.sentiment_compound;
        }, 0);

        const averageSentiment = totalSentiment / articleData.length;
        return Math.round(averageSentiment * 100) / 100; // Round to two decimal points
    };

    // Code for sidebar parameters

    // Update filters method
    const updateFilters = (newFilters) => {
        setArticleFilters(prevFilters => ({ ...prevFilters, ...newFilters }));
    };

    const [sourceOptions, setSourceOptions] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [sentimentOptions, setSentimentOptions] = useState([]);
    const [topicOptions, setTopicOptions] = useState([]);

    // useEffect(() => {
    //     // If sourceOptions are provided as a prop, set them directly
    //     if (propSourceOptions) {
    //         setSourceOptions(propSourceOptions);
    //     } else {
    //         // Otherwise, fetch the source options as before
    //         const fetchFilterOptions = async () => {
    //             try {
    //                 const response = await axios.post('https://fopotracking.ue.r.appspot.com/aggregations', {
    //                     fields: ['website_title', 'language', 'country']
    //                 });
    //                 if (response.data) {
    //                     setSourceOptions(response.data.website_title.map(source => ({ label: source, value: source })));
    //                     // console.log("L115");
    //                     // console.log(response.data.website_title.map(source => ({ label: source, value: source })));
    //                     setLanguageOptions(response.data.language.map(language => ({ label: language, value: language })));
    //                     setCountryOptions(response.data.country.map(country => ({ label: country, value: country })));
    //                 }
    //             } catch (error) {
    //                 console.error('Error fetching filter options:', error);
    //             }
    //         };
    //         fetchFilterOptions();
    //     }
    // }, [propSourceOptions]); // Add propSourceOptions as a dependency


    useEffect(() => {
        if (articleData.length > 0) {
            const sources = [...new Set(allArticleData.map(article => `${article.source} - ${article.source_url}`))]
                .sort()
                .map(source => ({ label: source, value: source }));
            setSourceOptions(sources);

            const languages = [...new Set(allArticleData.map(article => article.language))]
                .sort()
                .map(language => ({ label: language, value: language }));
            setLanguageOptions(languages);

            const countries = [...new Set(allArticleData.map(article => article.country))]
                .sort()
                .map(country => ({ label: country, value: country }));
            setCountryOptions(countries);

            const sentiments = [...new Set(allArticleData.map(article => mapSentiment(article.sentiment_compound).text))]
                .sort()
                .map(sentiment => ({ label: sentiment, value: sentiment }));
            setSentimentOptions(sentiments);

            const topics = [...new Set(allArticleData.flatMap(article => article.topics))]
                .sort()
                .map(topic => ({ label: topic, value: topic }));
            setTopicOptions(topics);
        }
    }, [allArticleData]);

    // Search by Source
    const [selectedSources, setselectedSources] = useState(null);
    const handleSourceChange = selectedOption => {
        setselectedSources(selectedOption);
        updateFilters({ sources: selectedOption.map(option => option.value) });
    };

    // Search by Language
    const [selectedLanguages, setSelectedLanguages] = useState(null);
    const handleLanguageChange = selectedOption => {
        setSelectedLanguages(selectedOption);
        updateFilters({ languages: selectedOption.map(option => option.value) });
    };

    // Search by Country
    const [selectedCountries, setselectedCountries] = useState(null);
    const handleCountryChange = selectedOption => {
        setselectedCountries(selectedOption);
        updateFilters({ countries: selectedOption.map(option => option.value) });
    };

    // Search by Topic
    const [selectedTopics, setselectedTopics] = useState(null);
    const handleTopicChange = selectedOption => {
        setselectedTopics(selectedOption);
        updateFilters({ topics: selectedOption.map(option => option.value) });
    };
    // const topicOptions = [
    //     "Arts, Culture, Entertainment and Media",
    //     "Conflict, War and Peace",
    //     "Crime, Law and Justice",
    //     "Disaster, Accident and Emergency Incident",
    //     "Economy, Business and Finance",
    //     "Education",
    //     "Environment",
    //     "Health",
    //     "Human Interest",
    //     "Labour",
    //     "Lifestyle and Leisure",
    //     "Politics",
    //     "Religion",
    //     "Science and Technology",
    //     "Society",
    //     "Sport",
    //     "Weather"
    // ].sort().map(topic => ({ label: topic, value: topic }));

    // Search by time range

    const handleTimeRangeChange = selectedOption => {
        setSelectedTimeRange(selectedOption);
        const now = new Date();
        let startDate = now; // Fallback to current date
        let endDate = new Date(now.setDate(now.getDate() + 1)); // Tomorrow's date as the end date

        switch (selectedOption.value) {
            case "Past 48 Hours":
                startDate = new Date(new Date().setDate(now.getDate() - 1));
                break;
            case "Past Week":
                startDate = new Date(new Date().setDate(now.getDate() - 7));
                break;
            case "Past Month":
                startDate = new Date(new Date().setMonth(now.getMonth() - 1));
                break;
            case "Past 4 Months":
                startDate = new Date('2024-01-01T00:00:00');
                break;
            case "Custom Range":
                // Intentionally left blank to allow for manual date entry
                return; // Exit the function to prevent automatic date updates
            default:
                break;
        }

        // Format the start and end dates
        const formattedStartDate = formatDateToString(startDate);
        const formattedEndDate = formatDateToString(endDate);

        // Update state and filters with the new start and end dates
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
        updateFiltersWithDates(formattedStartDate, formattedEndDate);
    };

    // Search by Date

    const [dateError, setDateError] = useState('');

    const validateDateFormat = (date) => {
        const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;
        return regex.test(date);
    };

    const validateDateRange = (start, end) => {
        if (!start) {
            return true; // Assuming a start date is required for validation to pass
        }
        const defaultEndDate = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
        const endDate = end ? new Date(end) : new Date(defaultEndDate);
        const startDate = new Date(start);

        return startDate < endDate;
    };

    const handleStartDateChange = (e) => {
        const newStartDate = e.target.value;
        setStartDate(newStartDate);

        if (!newStartDate || validateDateFormat(newStartDate)) {
            updateFilters({ date_published: [newStartDate, endDate] });
        }

        if (newStartDate === '') {
            setDateError('');
            return;
        }

        if (!validateDateFormat(newStartDate)) {
            setDateError('Please use MM/DD/YYYY.');
        }
        else if (!validateDateRange(newStartDate, endDate)) {
            setDateError('Start date must be before end date.');
        }
        else {
            setDateError('');
            updateFilters({ date_published: [newStartDate, endDate] });
        }
    };

    const handleEndDateChange = (e) => {
        const newEndDate = e.target.value;
        setEndDate(newEndDate);

        if (!newEndDate || validateDateFormat(newEndDate)) {
            updateFilters({ date_published: [startDate, newEndDate] });
        }

        if (newEndDate === '') {
            setDateError('');
            return;
        }

        if (!validateDateFormat(newEndDate)) {
            setDateError('Please use MM/DD/YYYY.');
        } else if (!validateDateRange(startDate, newEndDate)) {
            setDateError('Start date must be before end date.');
        } else {
            setDateError('');
            updateFilters({ date_published: [startDate, newEndDate] });
        }
    };


    const updateFiltersWithDates = (startDate, endDate) => {
        const newFilters = { ...articleFilters, date_published: [startDate, endDate] };
        setArticleFilters(newFilters);
        // If you're fetching data right after updating filters, consider calling fetchData here
        fetchArticleData(newFilters);
    };

    const formatDateToString = (date) => {
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    const timeRangeOptions = excludePast24Hours ? [
        { label: "Past Week", value: "Past Week" },
        { label: "Past Month", value: "Past Month" },
        { label: "Past 4 Months", value: "Past 4 Months" },
        { label: "Custom Range", value: "Custom Range" },
    ] : [
        { label: "Past 48 Hours", value: "Past 48 Hours" },
        { label: "Past Week", value: "Past Week" },
        { label: "Past Month", value: "Past Month" },
        { label: "Past 4 Months", value: "Past 4 Months" },
        { label: "Custom Range", value: "Custom Range" },
    ];
    const [selectedTimeRange, setSelectedTimeRange] = useState(timeRangeOptions.find(option => option.value === "Past 48 Hours"));



    // Search by Date
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    // Search by Sentiment
    const [selectedSentiments, setSelectedSentiments] = useState(null);
    const handleSentimentChange = selectedOption => {
        setSelectedSentiments(selectedOption);
        const selectedRanges = selectedOption.map(option => mapSentimentToRange(option.value));
        const minRange = Math.min(...selectedRanges.map(range => range[0]));
        const maxRange = Math.max(...selectedRanges.map(range => range[1]));
        updateFilters({ sentiment_compound: [minRange, maxRange] });
    };
    // const sentimentOptions = [
    //     "Strongly Positive",
    //     "Moderately Positive",
    //     "Neutral",
    //     "Moderately Negative",
    //     "Strongly Negative"
    // ].map(topic => ({ label: topic, value: topic }));
    const mapSentimentToRange = (text) => {
        switch (text) {
            case "Strongly Negative":
                return [-1.0, -0.6];
            case "Moderately Negative":
                return [-0.6, -0.2];
            case "Neutral":
                return [-0.2, 0.2];
            case "Moderately Positive":
                return [0.2, 0.6];
            case "Strongly Positive":
                return [0.6, 1.0];
            default:
                return [null, null]; // default case
        }
    };


    //Semantic Search
    const [selectedSemanticSearch, setSemanticSearch] = useState('');
    const handleSemanticSearchChange = (e) => {
        const searchValue = e.target.value;
        setSemanticSearch(searchValue);

        // Update the 'query' field of filters
        updateFilters({ query: searchValue });
    };

    const handleSearchClick = async () => {
        const activeFilters = { ...articleFilters };
        console.log("Line 764");
        // if (!startDate || !endDate) {
        //     delete activeFilters.date_published;
        // }

        try {
            setArticleDataIsLoading(true);
            fetchArticleData();
        } catch (error) {
            // Handle any errors
            console.error("Error during API call:", error);
        }
    };

    const handleRefreshClick = async () => {
        handleSearchClick();
    };


    // Code for UI of NarrativeAnalysis.js

    // Common JSX block
    const sidebar = (
        <div className="sidebar">
            <SideBar
                sourceOptions={sourceOptions}
                selectedSources={selectedSources}
                onSourceChange={handleSourceChange}

                languageOptions={languageOptions}
                selectedLanguages={selectedLanguages}
                onLanguageChange={handleLanguageChange}
                languageSearch={languageSearch}

                countryOptions={countryOptions}
                selectedCountries={selectedCountries}
                onCountryChange={handleCountryChange}
                countrySearch={countrySearch}

                topicOptions={topicOptions}
                selectedTopics={selectedTopics}
                onTopicChange={handleTopicChange}

                sentimentOptions={sentimentOptions}
                selectedSentiments={selectedSentiments}
                onSentimentChange={handleSentimentChange}
                showSentiment={true}

                timeRangeOptions={timeRangeOptions}
                selectedTimeRange={selectedTimeRange}
                onTimeRangeChange={handleTimeRangeChange}

                selectedSemanticSearch={selectedSemanticSearch}
                onSemanticSearchChange={handleSemanticSearchChange}

                startDate={startDate}
                onStartDateChange={handleStartDateChange}
                endDate={endDate}
                onEndDateChange={handleEndDateChange}
                dateError={dateError}

                handleSearchClick={handleSearchClick}
                handleRefreshClick={handleRefreshClick}

                trackerNameError={false}
                customTrackerCreator={false}
            />
        </div>
    );

    // Conditionally render DataTable content based on isLoading
    let clusterDataContent;

    if (clusterDataIsLoading) {
        clusterDataContent = (
            <div style={{ textAlign: "center" }}>
                <br /><br />
                <GridLoader />
            </div>
        );
    }
    else {
        clusterDataContent = (
            <div>
                <NarrativeHistory data={clusterData} />
            </div>
        );
    }

    // Conditionally render DataTable content based on noResults and isLoading
    let dataTableContent;


    if (!noResults && articleDataIsLoading) {
        dataTableContent = (
            <div className="data-table" style={{ textAlign: "center" }}>
                <br /><br />
                <GridLoader />
            </div>
        );
    }
    else if (clusterDataIsLoading) {
        dataTableContent = (
            <div className="data-table" style={{ textAlign: "center" }}>
                <br /><br />
                <GridLoader />
            </div>
        );
    }
    else if (noResults && articleDataIsLoading) {
        dataTableContent = (
            <div className="data-table" style={{ textAlign: "center" }}>
                {/* <br /><br />
                    <GridLoader /> */}
                <br />
                <h2>No results found.</h2>
            </div>
        );
    }
    else {
        dataTableContent = (
            <>
                <div className="data-table">
                    <DataTable
                        data={articleData}
                        semanticSearch={selectedSemanticSearch}
                        countryColumn={countryColumn}
                        languageColumn={languageColumn}
                        showSentiment={true}
                    />
                </div>
            </>
        );
    }



    const renderMobileNarrativeAnalysis = () => (
        <div>
            <div className="searchable-container">
                <div classname='sidebar'>
                    {sidebar}
                </div>
                <div className="text-content" style={{ textAlign: 'center' }}>
                    {clusterData.length > 0 && clusterData[0].current_title && (
                        <h4>{clusterData[0].current_title}</h4>
                    )}
                </div>
                <div className='data-table'>
                    <Tabs defaultActiveKey="insights" id="uncontrolled-tab-example" className="border-3">
                        <Tab eventKey="insights" title="Insights">
                            {!clusterData || clusterData.length === 0 || articleDataIsLoading ? (
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <br />
                                    <h3>Analyzing Articles...</h3>
                                    <br />
                                    <GridLoader />
                                </div>
                            ) : (
                                <>
                                    <div style={{ height: "0.5rem" }}></div>
                                    <i> Updated: {getDatePublished(clusterData[0].update_timestamp_utc)},
                                        {" "}{getTimePublished(clusterData[0].update_timestamp_utc)}
                                        {" "}({getTimezoneName(clusterData[0].update_timestamp_utc)})</i>

                                    <br /><br />
                                    <h5>Overview</h5>
                                    {clusterData[0].current_summary.split("\n").map((item, index) => (
                                        <div key={index}>{item.replace(/^- /, "• ")}</div>
                                    ))}
                                    <br />

                                    {articleDataIsLoading ? (
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            {noResults ? (
                                                <h3>No results found.</h3>
                                            ) : (
                                                <>
                                                    <h3>Analyzing Articles...</h3>
                                                    <br />
                                                    <GridLoader />
                                                </>
                                            )}
                                        </div>
                                    ) : (
                                        <>
                                            <h5>Started Tracking</h5>
                                            {getDatePublished(clusterData[0].creation_timestamp_utc)}, {getTimePublished(clusterData[0].creation_timestamp_utc)} ({getTimezoneName(clusterData[0].update_timestamp_utc)})
                                            <br /><br />
                                            <h5>Relevant Articles</h5>
                                            {articleData.length}
                                            <br /><br />
                                            <h5>Average Sentiment</h5>
                                            {mapSentiment(calculateAverageSentiment(articleData)).text}
                                            <div style={{ height: '0.75rem' }}></div>
                                            <hr />
                                            <div style={{ height: '0.75rem' }}></div>
                                            <h5>Top Article Sources</h5>
                                            <ul>
                                                {Object.entries(getTopSources(articleData)).map(([source, data], index) => (
                                                    <li key={index}>
                                                        <span style={{ fontWeight: 500 }}>{source} ({data.country})</span>: {data.count} {data.count === 1 ? 'Article' : 'Articles'}
                                                    </li>
                                                ))}
                                            </ul>
                                            <h5>Top Source Countries</h5>
                                            <ul>
                                                {Object.entries(getTopCountries(articleData)).map(([country, count], index) => (
                                                    <li key={index}>
                                                        <span style={{ fontWeight: 500 }}>{country}</span>: {count} {count === 1 ? 'Article' : 'Articles'}
                                                    </li>
                                                ))}
                                            </ul>
                                            <h5>Top Article Languages</h5>
                                            <ul>
                                                {Object.entries(getTopLanguages(articleData)).map(([language, count], index) => (
                                                    <li key={index}>
                                                        <span style={{ fontWeight: 500 }}>{language}</span>: {count} {count === 1 ? 'Article' : 'Articles'}
                                                    </li>
                                                ))}
                                            </ul>
                                            <div style={{ height: '0.5rem' }}></div>
                                            <hr />
                                            <div style={{ height: '0.75rem' }}></div>
                                            <h5>Most Mentioned Locations</h5>
                                            <ul>
                                                {Object.entries(getTopLocations(articleData)).map(([locations, count], index) => (
                                                    <li key={index}>
                                                        <span style={{ fontWeight: 500 }}>{locations}</span>: {count} {count === 1 ? 'Article' : 'Articles'}
                                                    </li>
                                                ))}
                                            </ul>
                                            <h5>Most Mentioned Organizations</h5>
                                            <ul>
                                                {Object.entries(getTopOrganizations(articleData)).map(([organizations, count], index) => (
                                                    <li key={index}>
                                                        <span style={{ fontWeight: 500 }}>{organizations}</span>: {count} {count === 1 ? 'Article' : 'Articles'}
                                                    </li>
                                                ))}
                                            </ul>
                                            <h5>Most Mentioned Persons</h5>
                                            <ul>
                                                {Object.entries(getTopPersons(articleData)).map(([persons, count], index) => (
                                                    <li key={index}>
                                                        <span style={{ fontWeight: 500 }}>{persons}</span>: {count} {count === 1 ? 'Article' : 'Articles'}
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    )}
                                </>
                            )}
                        </Tab>
                        <Tab eventKey="history" title="History">
                            {clusterDataContent}
                        </Tab>
                        <Tab eventKey="articles" title="Articles">
                            {dataTableContent}
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );

    const renderDesktopNarrativeAnalysis = () => (
        <div>
            <div className="text-content" style={{ textAlign: 'center' }}>
                <h1>Narrative Analysis</h1>
                {clusterData.length > 0 && clusterData[0].current_title && (
                    <h3>{clusterData[0].current_title}</h3>
                )}
            </div>
            <div className="searchable-container">
                <div classname='sidebar'>
                    {sidebar}
                </div>
                <div className='data-table'>
                    <Tabs defaultActiveKey="insights" id="uncontrolled-tab-example" className="border-3">
                        <Tab eventKey="insights" title="Key Insights">
                            {!clusterData || clusterData.length === 0 || articleDataIsLoading ? (
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <br />
                                    <h3>Analyzing Articles...</h3>
                                    <br />
                                    <GridLoader />
                                </div>
                            ) : (
                                <>
                                    <div style={{ height: "0.5rem" }}></div>
                                    <i>Updated: {getDatePublished(clusterData[0].update_timestamp_utc)},
                                        {" "}{getTimePublished(clusterData[0].update_timestamp_utc)}
                                        {" "}({getTimezoneName(clusterData[0].update_timestamp_utc)})</i>
                                    <br />
                                    <br />
                                    <h4>Overview</h4>
                                    {clusterData[0].current_summary.split("\n").map((item, index) => (
                                        <div key={index}>{item.replace(/^- /, "• ")}</div>
                                    ))}
                                    <br />
                                    <h4>Started Tracking: {getDatePublished(clusterData[0].creation_timestamp_utc)}, {getTimePublished(clusterData[0].creation_timestamp_utc)} ({getTimezoneName(clusterData[0].update_timestamp_utc)})</h4>

                                    {articleDataIsLoading ? (
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            {noResults ? (
                                                <h3>No results found.</h3>
                                            ) : (
                                                <>
                                                    <h3>Analyzing Articles...</h3>
                                                    <br />
                                                    <GridLoader />
                                                </>
                                            )}
                                        </div>
                                    ) : (
                                        <>
                                            <br />
                                            <h4>Relevant Articles: {articleData.length}</h4>
                                            <br />
                                            <h4>Average Sentiment: {mapSentiment(calculateAverageSentiment(articleData)).text}</h4>
                                            <br />
                                            <div className="plans-container">
                                                <div className="key-insights mb-2">
                                                    <h4>Top Article Sources</h4>
                                                    <div style={{ height: '0.5rem' }}></div>
                                                    <ul>
                                                        {Object.entries(getTopSources(articleData)).map(([source, data], index) => (
                                                            <li key={index}>
                                                                <span style={{ fontWeight: 500 }}>{source}</span>: {data.count} {data.count === 1 ? 'Article' : 'Articles'}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className="key-insights mb-2">
                                                    <h4>Top Source Countries</h4>
                                                    <div style={{ height: '0.5rem' }}></div>
                                                    <ul>
                                                        {Object.entries(getTopCountries(articleData)).map(([country, count], index) => (
                                                            <li key={index}>
                                                                <span style={{ fontWeight: 500 }}>{country}</span>: {count} {count === 1 ? 'Article' : 'Articles'}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className="key-insights mb-2">
                                                    <h4>Top Article Languages</h4>
                                                    <div style={{ height: '0.5rem' }}></div>
                                                    <ul>
                                                        {Object.entries(getTopLanguages(articleData)).map(([language, count], index) => (
                                                            <li key={index}>
                                                                <span style={{ fontWeight: 500 }}>{language}</span>: {count} {count === 1 ? 'Article' : 'Articles'}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="plans-container">
                                                <div className="key-insights mb-2">
                                                    <h4>Most Mentioned Locations</h4>
                                                    <div style={{ height: '0.5rem' }}></div>
                                                    <ul>
                                                        {Object.entries(getTopLocations(articleData)).map(([locations, count], index) => (
                                                            <li key={index}>
                                                                <span style={{ fontWeight: 500 }}>{locations}</span>: {count} {count === 1 ? 'Article' : 'Articles'}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className="key-insights mb-2">
                                                    <h4>Most Mentioned Organizations</h4>
                                                    <div style={{ height: '0.5rem' }}></div>
                                                    <ul>
                                                        {Object.entries(getTopOrganizations(articleData)).map(([organizations, count], index) => (
                                                            <li key={index}>
                                                                <span style={{ fontWeight: 500 }}>{organizations}</span>: {count} {count === 1 ? 'Article' : 'Articles'}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className="key-insights mb-2">
                                                    <h4>Most Mentioned Persons</h4>
                                                    <div style={{ height: '0.5rem' }}></div>
                                                    <ul>
                                                        {Object.entries(getTopPersons(articleData)).map(([persons, count], index) => (
                                                            <li key={index}>
                                                                <span style={{ fontWeight: 500 }}>{persons}</span>: {count} {count === 1 ? 'Article' : 'Articles'}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>

                                        </>
                                    )}
                                </>
                            )}
                        </Tab>
                        <Tab eventKey="history" title="Narrative History">
                            {clusterDataContent}
                        </Tab>
                        <Tab eventKey="visualize" title="Visualize">
                            <br />
                            {isMobile ? (
                                <h3 style={{ textAlign: "center" }}>To see visualizations, please visit VerbaAI on a tablet, laptop, or desktop.</h3>
                            ) : (
                                <>
                                    {articleDataIsLoading ? (
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            {noResults ? (
                                                <h3>No results found.</h3>
                                            ) : (
                                                <>
                                                    <br />
                                                    <GridLoader />
                                                </>
                                            )}
                                        </div>
                                    ) : (
                                        <>
                                            <div className="chart-container">
                                                {!clusterDataIsLoading && articleData.length > 0 && (
                                                    <div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <h3 style={{ textAlign: 'center' }}>Article Similarity</h3>
                                                            <i style={{ textAlign: 'center' }}>Based on semantic understanding of article text</i>
                                                        </div>
                                                        <ClusterSimilarity
                                                            articleTsne={articleTsneDict}
                                                            clusterTsne={clusterCentroidTsne}
                                                            articleData={articleData}
                                                        />
                                                        <br /><br />
                                                        <div style={{ textAlign: 'center' }}>
                                                            <h3 style={{ textAlign: 'center' }}>Sentiment Analysis</h3>
                                                            <i style={{ textAlign: 'center' }}>Based on lexical analysis of article text</i>
                                                        </div>
                                                        <ClusterSentiment
                                                            articleData={articleData}
                                                            clusterTimestamps={clusterTimestamps}
                                                            mapSentiment={mapSentiment}
                                                        />
                                                        {/* <br /><br />
                                                        <div style={{ textAlign: 'center' }}>
                                                            <h3 style={{ textAlign: 'center' }}>Contains Charged Language</h3>
                                                            <i style={{ textAlign: 'center' }}>Based on lexical analysis of article text</i>
                                                        </div>
                                                        <ClusterSentiment
                                                            articleData={articleData}
                                                            clusterTimestamps={clusterTimestamps}
                                                            mapSentiment={mapSentiment}
                                                        /> */}
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </Tab>
                        <Tab eventKey="articles" title="Relevant Articles">
                            {dataTableContent}
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            {isMobile ? renderMobileNarrativeAnalysis() : renderDesktopNarrativeAnalysis()}
        </div>
    );
};

export default NarrativeAnalysis;
