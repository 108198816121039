import { useParams } from "react-router-dom";
import "../GlobalStyles.css";
import SearchableTable from "../search/SearchableTable.js";
import { useUser } from "../../contexts/UserContext.js";
import Alert from "react-bootstrap/Alert";

const senatorList = [
    { firstName: 'Tommy', lastName: 'Tuberville' },
    { firstName: 'Katie Boyd', lastName: 'Britt' },
    { firstName: 'Lisa', lastName: 'Murkowski' },
    { firstName: 'Dan', lastName: 'Sullivan' },
    { firstName: 'Kyrsten', lastName: 'Sinema' },
    { firstName: 'Mark', lastName: 'Kelly' },
    { firstName: 'John', lastName: 'Boozman' },
    { firstName: 'Tom', lastName: 'Cotton' },
    { firstName: 'Alex', lastName: 'Padilla' },
    { firstName: 'Laphonza', lastName: 'Butler' },
    { firstName: 'Michael', lastName: 'Bennet' },
    { firstName: 'John W.', lastName: 'Hickenlooper' },
    { firstName: 'Richard', lastName: 'Blumenthal' },
    { firstName: 'Chris', lastName: 'Murphy' },
    { firstName: 'Tom', lastName: 'Carper' },
    { firstName: 'Chris', lastName: 'Coons' },
    { firstName: 'Marco', lastName: 'Rubio' },
    { firstName: 'Rick', lastName: 'Scott' },
    { firstName: 'Jon', lastName: 'Ossoff' },
    { firstName: 'Raphael', lastName: 'Warnock' },
    { firstName: 'Mazie', lastName: 'Hirono' },
    { firstName: 'Brian', lastName: 'Schatz' },
    { firstName: 'Mike', lastName: 'Crapo' },
    { firstName: 'Jim', lastName: 'Risch' },
    { firstName: 'Dick', lastName: 'Durbin' },
    { firstName: 'Tammy', lastName: 'Duckworth' },
    { firstName: 'Todd', lastName: 'Young' },
    { firstName: 'Mike', lastName: 'Braun' },
    { firstName: 'Chuck', lastName: 'Grassley' },
    { firstName: 'Joni', lastName: 'Ernst' },
    { firstName: 'Jerry', lastName: 'Moran' },
    { firstName: 'Roger', lastName: 'Marshall' },
    { firstName: 'Mitch', lastName: 'McConnell' },
    { firstName: 'Rand', lastName: 'Paul' },
    { firstName: 'Bill', lastName: 'Cassidy' },
    { firstName: 'John', lastName: 'Kennedy' },
    { firstName: 'Susan', lastName: 'Collins' },
    { firstName: 'Angus', lastName: 'King' },
    { firstName: 'Ben', lastName: 'Cardin' },
    { firstName: 'Chris', lastName: 'Van Hollen' },
    { firstName: 'Elizabeth', lastName: 'Warren' },
    { firstName: 'Ed', lastName: 'Markey' },
    { firstName: 'Debbie', lastName: 'Stabenow' },
    { firstName: 'Gary', lastName: 'Peters' },
    { firstName: 'Amy', lastName: 'Klobuchar' },
    { firstName: 'Tina', lastName: 'Smith' },
    { firstName: 'Roger', lastName: 'Wicker' },
    { firstName: 'Cindy', lastName: 'Hyde-Smith' },
    { firstName: 'Josh', lastName: 'Hawley' },
    { firstName: 'Eric', lastName: 'Schmitt' },
    { firstName: 'Jon', lastName: 'Tester' },
    { firstName: 'Steve', lastName: 'Daines' },
    { firstName: 'Deb', lastName: 'Fischer' },
    { firstName: 'Pete', lastName: 'Ricketts' },
    { firstName: 'Catherine', lastName: 'Cortez Masto' },
    { firstName: 'Jacky', lastName: 'Rosen' },
    { firstName: 'Jeanne', lastName: 'Shaheen' },
    { firstName: 'Maggie', lastName: 'Hassan' },
    { firstName: 'Bob', lastName: 'Menendez' },
    { firstName: 'Cory', lastName: 'Booker' },
    { firstName: 'Martin', lastName: 'Heinrich' },
    { firstName: 'Ben Ray', lastName: 'Luján' },
    { firstName: 'Chuck', lastName: 'Schumer' },
    { firstName: 'Kirsten', lastName: 'Gillibrand' },
    { firstName: 'Thom', lastName: 'Tillis' },
    { firstName: 'Ted', lastName: 'Budd' },
    { firstName: 'John', lastName: 'Hoeven' },
    { firstName: 'Kevin', lastName: 'Cramer' },
    { firstName: 'Sherrod', lastName: 'Brown' },
    { firstName: 'J.D.', lastName: 'Vance' },
    { firstName: 'James', lastName: 'Lankford' },
    { firstName: 'Markwayne', lastName: 'Mullin' },
    { firstName: 'Ron', lastName: 'Wyden' },
    { firstName: 'Jeff', lastName: 'Merkley' },
    { firstName: 'Bob', lastName: 'Casey, Jr.' },
    { firstName: 'John', lastName: 'Fetterman' },
    { firstName: 'Jack', lastName: 'Reed' },
    { firstName: 'Sheldon', lastName: 'Whitehouse' },
    { firstName: 'Lindsey', lastName: 'Graham' },
    { firstName: 'Tim', lastName: 'Scott' },
    { firstName: 'John', lastName: 'Thune' },
    { firstName: 'Mike', lastName: 'Rounds' },
    { firstName: 'Marsha', lastName: 'Blackburn' },
    { firstName: 'Bill', lastName: 'Hagerty' },
    { firstName: 'John', lastName: 'Cornyn' },
    { firstName: 'Ted', lastName: 'Cruz' },
    { firstName: 'Mike', lastName: 'Lee' },
    { firstName: 'Mitt', lastName: 'Romney' },
    { firstName: 'Bernie', lastName: 'Sanders' },
    { firstName: 'Peter', lastName: 'Welch' },
    { firstName: 'Mark', lastName: 'Warner' },
    { firstName: 'Tim', lastName: 'Kaine' },
    { firstName: 'Patty', lastName: 'Murray' },
    { firstName: 'Maria', lastName: 'Cantwell' },
    { firstName: 'Joe', lastName: 'Manchin' },
    { firstName: 'Shelley Moore', lastName: 'Capito' },
    { firstName: 'Ron', lastName: 'Johnson' },
    { firstName: 'Tammy', lastName: 'Baldwin' },
    { firstName: 'John', lastName: 'Barrasso' },
    { firstName: 'Cynthia M.', lastName: 'Lummis' }
];

const getIssueDetails = (lastName) => {
    let details = {};
    senatorList.forEach((senator) => {
        const senatorKey = senator.lastName.toLowerCase().replace(/ /g, '_');
        details[senatorKey] = {
            title: `Media Monitoring: Sen. ${senator.firstName} ${senator.lastName}`,
            filters: { persons: [`${senator.firstName} ${senator.lastName}`], countries: ["United States"] }
        };
    });

    return details[lastName.toLowerCase().replace(/ /g, '_')] || null;
};

const SenatorTracker = () => {
    let { issue } = useParams();
    const issueDetails = getIssueDetails(issue);
    const { user } = useUser();

    if (!issueDetails) {
        return <h1 style={{ textAlign: "center" }}>Issue Not Found</h1>;
    }

    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>{issueDetails.title}</h1>
            {!user && (
                <Alert style={{ backgroundColor: '#222222', color: '#F8F9FA', padding: '10px 20px' }} className="text-center">
                    Sign in to VerbaAI to access additional features, including bookmarking
                    speeches and creating custom trackers.
                </Alert>
            )}
            <SearchableTable
                key={issue}
                baseRequest={issueDetails.filters}
                excludePast24Hours={true}
                countrySearch={false}
                languageSearch={false}
                mediaMonitor={true}
                personOfInterest={issueDetails.filters.persons[0]}
                trackerType={["current congress"]}
            />
        </div>
    );
};

export default SenatorTracker;
